body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: rgb(53, 53, 54);
  background: radial-gradient(
    circle,
    rgba(53, 53, 54, 1) 0%,
    rgba(28, 28, 28, 1) 47%,
    rgba(0, 0, 0, 1) 100%
  );
}
